.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}


.App-header {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.img-drop {
  filter: drop-shadow(0rem 0rem .5rem #7a705b);
}

.App-link {
  color: #61dafb;
}

.buttonClass {
  font-size:15px;
  font-family: Arial;
  color:#2a4668;
  border-color:#504f4f;
  font-weight:bold;
  
  padding-left: 20px;
  padding-right: 20px;

  transition: 0.2s;
  align-items: center;
  display: flex;
  border-top-left-radius:10px;
  border-top-right-radius:10px;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
  box-shadow: 0px 0px 5px -2px #ffa409;
  background:linear-gradient(#e7b66b, #ffa409);
}

.buttonClass:hover {
  box-shadow: 2px 2px 10px 0px rgba(228, 195, 113, 1);
}
